import React from 'react';
import Image from 'material-ui-image';

const Header = () => {
	return (
		<Image
			src={`/static/images/header.png`}
			aspectRatio={(16 / 9)}
		/>
	);
};

export default Header;
