import React from 'react';
import { Container, Paper, Typography, Box } from '@material-ui/core';
import Header from './components/header/header';

function App() {
  return (
    <div className="App">
      <Container>
        <Box mb={1}>
          <Paper variant="outlined">
            <Header />
            <Box p={2}>
              <Typography variant="h5" component="h1">
                <strong>hylf</strong>
                <sub>[ˈhɪlf]</sub> - <strong>h</strong>elp <strong>y</strong>our{' '}
                <strong>l</strong>ocal <strong>f</strong>ans!
              </Typography>
              <Box p={2}>
                <Typography component="p">- coming soon -</Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Container>
    </div>
  );
}

export default App;
