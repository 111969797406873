const colors = {
    primary: {
        light: '#45A803',
        base: '#046602',
        dark: '#255A02',
        darker: '#193D01',
    },
    secondary: {
        light: '#FF991C',
        base: '#ff9c00',
        dark: '#AD6813',
        darker: '#824E0E'
    },
    ui: {
        background: '#f2f2f2',
        base: '#eeeeee',
        dark: '#cccccc',
        darker: '#aaaaaa',
        font: '#444444'
    },
    black: '#222222',
    white: '#FFFFFF'
};

const baseUnit = 16;

const spacings = {
    tiny: baseUnit/4 + 'px',
    small: baseUnit/2 + 'px',
    baseline: baseUnit + 'px',
    large: baseUnit*2 + 'px',
    huge: baseUnit*4 + 'px'
};

const transitions = {
    ease: 'all 0.3s ease'
};

const boxShadows = {
    default:
        '0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12)'
};

const fonts = {
    huge: '30px',
    large: '24px',
    regular: '16px',
    small: '14px',
    tiny: '12px'
};

enum zIndex {
    Default
}

export default {
    colors,
    spacings,
    transitions,
    boxShadows,
    fonts,
    zIndex
};
